.loginHeader {
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.w-60 {
  width: 60%;
}

.loginPage-header {
  padding: 10px 10px 10px;
}

/* .loginPage-header img{
    max-width: 150px;
} */
.loginheaderLogo {
  padding: 10px 20px 10px;
}

.loginheaderLogo img {
  width: 90% !important;
}

.loginForm {
  font-family: "Raleway", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.loginForm img {
  width: 100%;
}

.logFont {
  margin-top: 70px;
  margin-left: 126px;
}

.logFont h1 {
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 50px;
}

/* .buttonLogin{
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px;
} */
.btn-login {
  background-color: #1962a4;
  border-color: #fff;
  padding: 10px 20px 10px;
  width: 100%;
}

.btn-login:hover {
  background-color: #2b7dc5;
  border-color: #fff;
}

.btn-login:active:focus {
  background-color: #2b7dc5;
  border-color: #fff;
}

.btn-login:focus {
  background-color: #2b7dc5;
  border-color: #fff;
  box-shadow: none !important;
}

.form-floating .form-control:focus {
  border-color: none;
  box-shadow: none;
}

.dashboard_togglebtn {
  box-shadow: 0px 0px 10px rgb(113 121 136 / 10%);
  background-color: #fff;
  padding: 4px 8px 4px;
  border-radius: 10px;
}

.dashboard_togglebtn span :hover {
  color: #343274;
}

/* form */
.form_sec {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 30px 30px;
}

.login-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-page .login-form {
  width: 500px;
  background: #fff;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
  padding: 30px 30px 30px 30px;
}

.login-page .login-text {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.login-page .height-50 {
  height: 50px;
}

.login-page .login-btn {
  background-color: #343274;
  border-color: #343274;
  font-size: 12px;
  padding: 5px 40px;
  margin-top: 20px;
  height: 50px;
  width: 100%;
}

.login-page .forgot-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  background-color: #eeedf1;
  height: 50px;
  box-shadow: 0px 30px 50px 0px rgba(0, 0, 0, 0.2);
}

.login-page .forgot-text {
  font-size: 10px;
  color: #3ca9e2;
  margin-right: 30px;
}

.form-label {
  color: #3c535e;
  font-size: 12px;
  font-weight: 500;
}

/* input:focus {
    box-shadow: none;
} */

textarea:focus {
  box-shadow: none;
}

::placeholder {
  font-size: 14px;
}

.form-select {
  font-size: 14px;
  border: 1px solid #ced4da;
  color: #7c858e;
}

/* .select_class{
  font-size: 14px;
  border: 1px solid #929292;
 
} */
/* .css-b62m3t-container {
    position: relative;
    /* box-sizing: border-box; */
/* color: red; */
/* border: 1px solid #929292 ; */

.form-control {
  border: 1px solid #ced4da;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
}

.form-select:focus {
  box-shadow: none;
}

.css-13cymwt-control {
  border-color: #ced4da;
}

.save-btn {
  background-color: #343274;
  border-color: #343274;
  font-size: 12px;
  padding: 5px 40px;
  margin-top: 20px;
}

.save-btn:hover {
  border-color: #343274;
  background-color: #343274;
}

.save-btn:active {
  border-color: #343274;
  background-color: #343274;
  box-shadow: none;
}

.save-btn:focus {
  border-color: #343274;
  background-color: #343274;
  box-shadow: none;
}

.save-btn:active:focus {
  box-shadow: none;
}

.add-new-btn {
  background-color: #343274;
  border-color: #343274;
  font-size: 12px;
  padding: 5px 40px;
  margin-top: 20px;
}

.add-new-btn:hover {
  border-color: #343274;
  background-color: #343274;
}

.add-new-btn:active {
  border-color: #343274;
  background-color: #343274;
  box-shadow: none;
}

.add-new-btn:focus {
  border-color: #343274;
  background-color: #343274;
  box-shadow: none;
}

.add-new-btn:active:focus {
  box-shadow: none;
}

.svg-icon svg {
  color: #343274;
}

.btn-icon:focus {
  box-shadow: none;
}

/* Sweetalert */
.swal2-actions .btn-primary {
  background-color: #343274;
}

.swal2-actions .btn-primary:hover {
  background-color: #3c8cc9;
}

.swal2-actions .btn-primary:focus {
  box-shadow: none;
}

.swal2-actions .btn-primary:active:focus {
  box-shadow: none;
}

.swal2-actions .btn-danger:focus {
  box-shadow: none;
}

.swal2-actions .btn-danger:active:focus {
  box-shadow: none;
}

/* Toaster */
.toast-msg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  width: 350px;
  top: 30px;
  position: fixed;
  box-shadow: 1px 7px 14px -5px rgb(0 0 0 / 20%);
  z-index: 9999;
}

.toast--green:before {
  background-color: #2bde3f;
}

.toast--red:before {
  background-color: #d8000c;
}

.toast--warning:before {
  background-color: #f9a02c;
}

.toast-msg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.toaster-setting {
  position: absolute;
  top: 70px;
  right: 12px;
}

/* Date picker */
.react-date-picker__wrapper {
  border: 0 !important;
}

.react-date-picker {
  padding: 3px 12px 3px 12px;
}

/* Time picker */
.react-time-picker__wrapper {
  border: 0 !important;
}

.react-time-picker {
  padding: 3px 12px 3px 12px;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.contant_box_404 .whoops {
  color: #0e62a3;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-align: initial;
}

.contant_box_404 .something_went {
  color: #0e62a3;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-align: initial;
}

.contant_box_404 .h2 {
  color: #0e62a3;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  text-align: initial;
}

.contant_box_404 .cant_find {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.four_zero_four_bg {
  height: 300px;
  background-position: center;
  /* // margin-top: 90px; */
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  font-size: 14px;
  padding: 10px 32px;
  background: #0e62a3;
  /* margin: 20px 0; */
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.contant_box_404 {
  position: absolute;
  top: 34%;
  right: 10%;
  margin-top: 30px;
}

.call_duration_modal .modal-body {
  padding: 20px 20px 20px 20px;
}

.call_duration_modal .call_duration {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.call_duration_modal .call_charge {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.go_home {
  text-align: left;
}

@media (max-width: 968px) {
  .contant_box_404 {
    position: initial;
    margin-top: 190px;
    margin-left: 20px;
  }

  .four_zero_four_bg {
    height: 180px;
  }

  .contant_box_404 .whoops {
    font-size: 34px;
  }

  .contant_box_404 .something_went {
    font-size: 34px;
  }

  .contant_box_404 .h2 {
    font-size: 34px;
  }

  .contant_box_404 .cant_find {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .contant_box_404 {
    position: initial;
    margin-top: 110px;
    margin-left: 20px;
  }

  .four_zero_four_bg {
    height: 180px;
  }

  .contant_box_404 .whoops {
    font-size: 34px;
  }

  .contant_box_404 .something_went {
    font-size: 34px;
  }

  .contant_box_404 .h2 {
    font-size: 34px;
  }

  .contant_box_404 .cant_find {
    font-size: 14px;
  }
}

@media (max-width: 568px) {
  .contant_box_404 {
    position: initial;
    margin-top: 94px;
    margin-left: 20px;
  }

  .four_zero_four_bg {
    height: 180px;
  }

  .contant_box_404 .whoops {
    font-size: 34px;
  }

  .contant_box_404 .something_went {
    font-size: 34px;
  }

  .contant_box_404 .h2 {
    font-size: 34px;
  }

  .contant_box_404 .cant_find {
    font-size: 14px;
  }
}

@media (max-width: 468px) {
  .contant_box_404 {
    position: initial;
    margin-top: 14px;
    margin-left: 20px;
  }

  .four_zero_four_bg {
    height: 180px;
  }

  .contant_box_404 .whoops {
    font-size: 34px;
  }

  .contant_box_404 .something_went {
    font-size: 34px;
  }

  .contant_box_404 .h2 {
    font-size: 34px;
  }

  .contant_box_404 .cant_find {
    font-size: 14px;
  }

  /* SEARCH */
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  justify-content: end;
  padding: 14px;
}

.rdt_TableHead {
  font-weight: 600 !important;
  font-size: 14px;
  text-transform: uppercase;
}

/* password eye button  */
.icon-eye {
  position: relative;
}

.icon-eye svg {
  margin-left: 175px;
  bottom: 8px;
  color: #012970;

  position: absolute;
}

.icon-eye-two {
  position: relative;
}

.icon-eye-two svg {
  bottom: 8px;
  right: 28px;
  color: #012970;
  position: absolute;
}

input[type="password"]::-ms-reveal {
  display: none;
}

.login_btn_wrapper {
  text-align: center;
}

.MuiFormControl-root {
  border: 1px solid #bdbdbd !important;
  width: 100%;
}

.usrname {
  border-radius: 33px;
  height: 50px;
  /* width: 367px; */
  padding: 13px 27px 13px 27px;
  width: 399px;
}

.usrnam {
  border-radius: 33px;
  height: 50px;
  /* width: 367px; */
  padding: 13px 27px 13px 27px;
  width: 399px;
}

.login_btn {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: darkblue;
  border: 0;
  padding: 4px 50px 4px 51px;
  border-radius: 23px;
}

.reset_text {
  font-size: 23px;
  margin-bottom: 18px;
  color: darkblue;
}

.required {
  color: #d8000c;
  font-size: 10pt;
  font-weight: 500;
}
.invalid-feedbacks {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.css-1epcaey-control {
  border-color: #f6f2f2 !important;
}

.image {
  padding: 0;
}

.Login_page {
  height: 100vh;
  display: flex;
  align-items: center;
}

.img_wrapper {
  height: 370px;
  width: 100%;
  object-fit: contain;
}

.img_wrapper img {
  height: 370px;
  width: 100%;
  object-fit: contain;
}

.login_btn_wrappers {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 16px;
}

.login_btn {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: darkblue;
  border: 0;
  padding: 6px 40px 6px 40px;
  border-radius: 23px;
}

.input-login {
  margin-bottom: 15px;
}

.login_btns {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: darkblue;
  padding: 6px 40px 6px 40px;
  border-radius: 23px;
}

.back-to-text {
  font-size: 12px;
}

.login_btnss {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: darkblue;
  padding: 6px 33px 6px 33px;
  border-radius: 23px;
}

.forgo-text {
  justify-content: flex-start;
  margin-right: 125px;
  font-size: 27px;
  padding-bottom: 34px;
}

.back-to-text {
  font-size: 12px;
  margin-left: 150px;
}

.forgot-text {
  color: #cdcdcd;
  text-align: center;
}

.log-text {
  color: darkblue;
}

.ellipse_email {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal_content {
  display: flex;
  align-items: flex-end;
}

.bulk_upload {
  font-size: 29px;
  font-weight: 600;
}

.bulk_button {
  display: flex;
  flex-direction: row-reverse;
}

.box {
  width: auto;
}

icon-eye-hid {
  margin: left 50px;
}

.eye_button {
  position: relative;
}

.eye_button svg {
  position: absolute;
  bottom: 15px;
  right: 34px;
}

.icon_eyes {
  position: relative;
}

.icon_eyes svg {
  position: absolute;
  bottom: 18px;
  left: 334px;
}

.invalid-feedbacks {
  display: none;
  width: 400px;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.search-space {
  height: fit-content;
  margin-top: 4px;
  margin-left: 3px;
}

.MuiInputBase-input {
  padding: 5.5px 14px !important
}

.ck-editor__editable {
  min-height: 100px;
}

/* .MuiChcckbox-colorSecondary.Mui-checked {
  color: #012970;
} */
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #343274 !important;

}

/* .MuiIconButton-colorSecondary{
  color: #343274 !important;
} */
.feedback_errors {
  width: 400px;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.btnss {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: darkblue;
  padding: 6px 40px 6px 40px;
  border-radius: 23px;
}

/* .tilt{

} */
.chngpw {
  color: black
}

.country_title {
  color: #343274
}

.red-border {
  border: 1px solid red;
}




/*  */

.MuiPaper-rounded {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.filter-main {
  margin: 20px 16px;
}

.searchEnquiry .form-control {
  border: 1px solid rgb(0 0 0 / 43%) !important;
}

.filterEnquiry .form-select {
  border: 1px solid rgb(0 0 0 / 43%) !important;
}

.fkmpGg {
  margin-left: 10px;
}

.rdt_TableRow {
  padding: 10px 0px;
}

.rdt_TableRow .align-items-center img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.brochure_download_btn {
  border-radius: 0px 5px 5px 0px !important;
  padding: 8px 14px !important;
  max-height: 36px;
}

.brochure_download_input:focus {
  box-shadow: none !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.pt-10 {
  padding-top: 10px;
}

.hsprtj:disabled {
  fill: rgb(0 0 0 / 36%) !important;
}

.hsprtj {
  fill: rgb(0 0 0 / 70%) !important;
}

.hLETqJ {
  color: rgb(0 0 0 / 77%);
}

.EBmps {
  color: rgb(0 0 0 / 77%);
}

.kXhSWm {
  color: rgb(0 0 0 / 77%);
}

.css-1epcaey-control {
  border: 1px solid #ced4da !important;
}

.is-invalid:focus {
  box-shadow: none !important;
}

.hr-divider {
  height: 1px;
  width: 90%;
  background-color: rgb(181 186 191);
  margin-top: 40px;
}

.delete_btn {
  margin-bottom: 15px;
}

.pos-relative {
  position: relative;
}

.pos-relative .view_btn {
  position: absolute;
  top: 0;
  right: 8px;
  border-radius: 0px 5px 5px 0px !important;
}
.pos-relative .brochure-view-btn{
  top: 28px;
left: 111px;
right: unset;
height: 35px;
width: 100px;
}
.pos-relative .brochure-delete-btn{
  padding: 0.49rem 1.2rem;
  margin: 0px 0px 0px -2px;
  border-radius: 0.25rem;
  position: absolute;
  top: 28px;
  left: 215px;
  right: unset;
  height: 35px;
  width: 100px;
}
.selected-file-name{
  padding-top: 7px;
  font-size: 13px;
  color: #3a3434;
  font-weight: 600;
}

.form-select:focus {
  box-shadow: none !important;
}

.hr-divider-country {
  height: 1px;
  width: 100%;
  background-color: rgb(181 186 191);
  margin-top: 40px;
  margin-bottom: 30px;
}

.file_wrapper .img-wrapper {
  display: flex;
  gap: 20px;
}

.file_wrapper .img-wrapper img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.view_image_wrapper .image-item {
  position: relative;
}

.file_wrapper .img-wrapper .close_icon {
  position: absolute;
  top: 14px;
  right: -8px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #093384;
  background: #093384;
  border-radius: 25px;
  font-size: 22px;
  padding: 2px 2px;
}

.view_image_wrapper {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(7, 1fr);
}

.size {
  color: #093384;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
}

.isopen_status {
  display: flex;
  align-items: center;
  gap: 20px;
}

.isopen_status .form-check-input {
  width: 24px;
  height: 24px;
}
.btn-del{
  margin-right: 5px !important;
}
/* .brochure input[type="file"]::file-selector-button {
    display: none;
  } */
