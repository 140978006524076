.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.filterEnquiry {
  width: 200px;
  top: 45px;
  left: 20%;
  z-index: 1;
}

.countryFilter {
  width: 200px;
  top: 45px;
  left: 47.5%;
  z-index: 1;
}

.searchEnquiry {
  width: 200px;
  top: 45px;
  right: 20px;
  /* z-index: 1; */
  display:flex;
}
.tooltip_search{
  padding: 5px 5px 5px 5px;
}

.filter-main{
  display:flex;
  justify-content: space-between;
  min-width:650px;
}

.input-style {
  border: 1px solid #000;
  z-index: 1
}

option {
  color: #000;
}

.errorMessage {
  font-size: 0.875em;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.max-char {
  color: #198754;
  font-weight: bold;
  font-size: 15px;
  margin-top: 0.25rem
}

.cmkfnw {
  min-height: 70px !important;
}

.fff {
  display: flex;
  width: 400px;
}

.delete-btn {
  margin-top: 31px;
  padding: 7px 25px;
}

.form-check-input:checked {
  background-color: #343274;
  border-color: #343274;
}